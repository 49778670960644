<style lang="scss">
.main {
	@extend .center, .content;
	padding-bottom: 10px;
}

.cms {
	@extend .content;
	margin-top: 5px;
	padding: 10px 5px;

	.caption {
		display: inline-block;
		margin-bottom: 10px;
	}

	.action {
		background-color: $nav-color;
		border-radius: 3px;
		padding: 5px 10px;
		color: $content-color;
		margin-left: 10px;
	}
}

.caption {
	font-size: $large-size;
	font-weight: $caption-weight;
	color: $caption-color;
}

.waiter {
	margin-right: 10px;
}

.tag {
	margin: 0 5px;
	color: #ccc;
	// background-color: #f5f5f5;
}

li {
	@extend .row;
	margin-bottom: 8px;

	.item {
		font-size: $middle-size;
		font-weight: $caption-weight;
		color: $text-color;
		margin-right: 30px;
		width: 100px;
	}

	.content {
		font-size: $middle-size;
	}
}

.notice {
	@extend .content;
	font-size: $large-size;
	color: $warning-color;
}
</style>
<template>
	<div id="app">
		<div class="notice" v-if="warning">{{ warning.info }}</div>
		<template v-if="model">
			<div class="cms">
				<span class="caption">客户信息(坚守者)</span>
				<ul>
					<li>
						<span class="item">激活状态</span>
						<span>
							<span class="content">{{ statusText }}</span>
						</span>
					</li>
					<template v-if="model.isKeeper > 0">
						<li>
							<span class="item">坚守者</span
							><span class="content">是</span>
						</li>
						<li>
							<span class="item">加入计划时间</span
							><span class="content">{{ datetime }}</span>
						</li>
						<li>
							<span class="item">商城已消费</span>
							<span class="content">{{
								model.keeperDebt - model.waitCapital
							}}</span>
						</li>
						<li>
							<span class="item">当前份额</span>
							<span class="content">{{ model.waitCapital }}</span>
						</li>
					</template>
				</ul>
			</div>
			<div class="cms">
				<span class="caption">账户信息(爱投资)</span>
				<ul>
					<li>
						<span class="item">姓名</span
						><span class="content">{{ model.name }}</span>
					</li>
					<li>
						<span class="item">身份证号码</span
						><span class="content">{{ model.identity }}</span>
					</li>
					<li>
						<span class="item">注册手机号</span
						><span class="content">{{ model.phone }}</span>
					</li>
				</ul>
			</div>
			<div class="cms" v-if="model.waiters1">
				<span class="caption">冰田客服信息({{ model.waiters1 }})</span>
				<div v-html="waiterList1()"></div>
			</div>
			<div class="cms" v-if="model.waiters">
				<span class="caption">坚守者客服信息({{ model.waiters }})</span>
				<div v-html="waiterList()"></div>
			</div>
		</template>
		<div class="cms">
			<a class="action" @click="involve">发送商城小程序 </a>
		</div>
		<div class="cms">
			<a v-if="initlize" class="action" @click="approve">{{
				(status ? '关闭' : '开启') + '入驻商登录资格'
			}}</a>
		</div>
	</div>
</template>
<script>
export default {
	name: 'index',
	data() {
		return {
			jsApiList: ['getCurExternalContact', 'sendChatMessage'],
			model: null,
			// nomral: false,
			// need: false,
			warning: null,
			identity: null,
			status: false,
			initlize: false,
		};
	},

	computed: {
		statusText: function () {
			if (!this.model) return '';
			switch (this.model.status) {
				case -1:
					return '未绑定';
				case 0:
					return '已绑定未激活';
				case 1:
					return '已激活';
			}
			return '';
		},
		datetime: function () {
			if (!this.model || !this.model.keeperTime) return '';
			var d = new Date(this.model.keeperTime * 1000);
			return [d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-');
		},
	},

	async mounted() {
		let result;
		try {
			result = await this.inject();
			console.log('注入agentConfig成功', result);
		} catch (error) {
			console.log('注入agentConfig失败', error);
			this.warn(error);
			return;
		}
		try {
			result = await this.invoke();
			console.log('获取客户OK', result.userId);
			this.identity = result.userId;
		} catch (error) {
			console.log('获取客户不ok', error);
			this.warn(error);
			return;
		}

		try {
			result = await this.invoke();
			console.log('获取客户OK', result.userId);
			this.identity = result.userId;
		} catch (error) {
			console.log('获取客户不ok', error);
			this.warn(error);
			return;
		}

		try {
			result = await this.axios.get('/oauth/union', {
				params: {
					id: this.identity,
				},
			});
			result = result.data;

			if (result.code === 0) {
				this.identity = result.data.external_contact.unionid;
				console.log('haha', this.identity);
			} else {
				this.warn(result.info);
			}
		} catch (error) {
			console.log('获取商户信息失败:', error);
			this.warn(error);
		}

		try {
			result = await this.axios.get('/user/info', {
				params: {
					id: this.identity,
				},
			});
			result = result.data;
			console.log('用户信息', result);
			if (result.code === 0) {
				this.model = result.data;
				console.log('获取用户信息成功:', this.model);
			} else {
				this.warn(result.info);
			}
		} catch (error) {
			console.log('获取用户信息成功:', error);
			this.warn(error);
		}

		try {
			result = await this.axios.get('/merchant/info', {
				params: {
					id: this.identity,
				},
			});
			result = result.data;
			if (result.code === 0) {
				this.status = result.data.length > 0;
				this.initlize = true;
				console.log('获取s商户信息成功:', this.status);
			} else {
				this.warn(result.info);
			}
		} catch (error) {
			console.log('获取商户信息失败:', error);
			this.warn(error);
		}
	},

	methods: {
		inject() {
			return new Promise((resolve, reject) => {
				const config = Object.assign(
					{
						jsApiList: this.jsApiList,
						success: resolve,
						fail: reject,
					},
					window.CONFIG
				);
				console.log('agentConfig内容:', config);
				window.wx.agentConfig(config);
			});
		},

		invoke() {
			return new Promise((resolve, reject) => {
				window.wx.invoke(
					'getCurExternalContact',
					{},
					function (result) {
						if (result.err_msg == 'getCurExternalContact:ok')
							resolve(result);
						else reject(result);
					}
				);
			});
		},

		warn(info) {
			this.warning = { info };
			this.need = true;
		},

		waiterList() {
			if (this.model.waiters == 0)
				return '<span class="hilite">没有客服</span>';
			return this.model.waiter
				.split('||')
				.map((item) =>
					item
						.split(':')
						.map((element, index) => {
							if (index == 0)
								return `<span class="waiter">${element}</span>`;
							else {
								if (element)
									return element
										.split('|')
										.map(
											(cell) =>
												`<span class="tag">${cell}</span>`
										)
										.join('');
								else return '';
							}
						})
						.join('')
				)
				.join('<br/>');
		},

		waiterList1() {
			if (this.model.waiters1 == 0)
				return '<span class="hilite">没有客服</span>';
			return this.model.waiter1
				.split('||')
				.map((item) =>
					item
						.split(':')
						.map((element, index) => {
							if (index == 0)
								return `<span class="waiter">${element}</span>`;
							else {
								if (element)
									return element
										.split('|')
										.map(
											(cell) =>
												`<span class="tag">${cell}</span>`
										)
										.join('');
								else return '';
							}
						})
						.join('')
				)
				.join('<br/>');
		},

		async approve() {
			if (
				!confirm(
					`是否确定${this.status ? '关闭' : '开启'}入驻商登录资格？`
				)
			)
				return;
			try {
				let result = await this.axios.post('/merchant/approve', {
					id: this.identity,
					status: !this.status,
				});
				console.log(result);
				if (result.data.code == 0) {
					this.status = !this.status;
					console.log('操作成功:', result.data);
				} else console.log('操作失败:', result.data);
			} catch (error) {
				console.log('操作失败:', error);
			}
			// window.wx.invoke(
			//   "sendChatMessage",
			//   {
			//     msgtype: "miniprogram",
			//     miniprogram: {
			//       appid: "wxa03a7f85f5989deb",
			//       title: "兑呗优选",
			//       imgUrl: "https://steward.subyke.com/logo.png",
			//       page: "/pages/index/index.html",
			//     },
			//   },
			//   function (res) {
			//     console.log("发送结果", res);
			//   }
			// );
		},

		async involve() {
			if (!confirm('是否发送商城小程序？')) return;
			window.wx.invoke(
				'sendChatMessage',
				{
					msgtype: 'miniprogram',
					miniprogram: {
						appid: 'wx8842f8c9f6415970',
						title: 'SubykeMall',
						imgUrl: 'https://steward.subyke.com/logo.png',
						page: '/pages/index/index.html',
					},
				},
				function (res) {
					console.log('发送结果', res);
				}
			);
		},
	},
};
</script>
